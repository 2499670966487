import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SideNav from './components/SideNav';
import Orders from './pages/Orders';
import AuthRedirect from './pages/AuthRedirect';
import SingleOrder from './pages/SingleOrder';
import SignOn from './pages/SignOn';
import SingleInvoice from './pages/SingleInvoice';
import Invoices from './pages/Invoices';
import RMAs from './pages/RMAs';
import Home from './pages/Home';
import Payments from './pages/Payments';
import Statements from './pages/Statements';
import GetLoginDetails from './pages/GetLoginDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ForgotPassword from './pages/ForgotPassword';
import TopNav from './components/TopNav';
import Footer from './components/Footer';
import Auth from './utils/auth';
import SelectCompany from './pages/SelectCompany';
import Tracking from './pages/Tracking';
import DataLoading from './components/DataLoading';
import GLOBALS from './utils/global_variables';

function App() {
	document.title = "Direct Components Customer Portal";
	GLOBALS.getDefaultsFromStorage();
	return (<>
		<TopNav />
		<section className="top-level" style={{ flexDirection: 'column' }}>
			{!Auth.loggedIn() 
      	? (
			<Router>
				<Routes>
					<Route path='/login' element={<SignOn />} />
					<Route path='/login/get-details' element={<GetLoginDetails />} />
					<Route path='/login/forgot' element={<ForgotPassword />} />
					<Route path='*' element={<SignOn />} />
				</Routes>
			</Router>
			) 
      	: (
			<Router>
			{Auth.getAccountLength() > 1 && JSON.parse(localStorage.getItem("accindex")) === null 
			? (
				<Routes>
					<Route path='*' element={<SelectCompany />} />
				</Routes>
				)
			: 
				<>
				<SideNav />
				<ToastContainer />
				<div className="content-body">
				{sessionStorage.getItem('account') === null
				?
				(<>
					<Routes>
					<Route path="*" element={<AuthRedirect />} />
					</Routes>
				</>)
				:
					(<>
					<DataLoading />
					<Routes>
					<Route path='/home' element={<Home />} />
					<Route path="/orders" element={<Orders />} />
					<Route path="/auth/redirect" element={<AuthRedirect />} />
					<Route path="/orders/:orderid" element={<SingleOrder />} />
					<Route path="/invoices/:invoiceid" element={<SingleInvoice />} />
					<Route path="/tracking" element={<Tracking />} />
					<Route path="/invoices" element={<Invoices />} />
					<Route path="/RMAs" element={<RMAs />} />
					<Route path='/payments' element={<Payments />} />
					<Route path='/statements' element={<Statements />} />
					<Route path='*' element={<Home />} />	
					</Routes>

					</>)}
				</div>
				</>
			}
			</Router>
		)}
		</section>
		<Footer />
	</>);
}

export default App;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate, invoiceNumToId } from '../../utils/helpers';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';

const handleStatus = (status) => {
    if (status === 'approved' || status === 'credit customer' || status === 'further testing required') {
        return ['Approved', 'green'];
    } else if (status === 'denied') {
        return ['Denied', 'red'];
    } else if (status === 'pending' || status === 'vendor rma needed' || status === 'management review') {
        return ['Pending', 'orange'];
    } else if (status === 'archive') {
        return ['Completed']
    } else {
        return [`${status[0].toUpperCase()}${status.substring(1)}`];
    }
}
async function getRMAs () {
    if (sessionStorage.getItem('RMAs') === null) {
        const account_name = JSON.parse(sessionStorage.getItem('account')).company_name;
        const response = await fetch(`/api/RMAs/search/`, {
            method: "POST",
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                search: account_name
            })
        });
        let data;
        data = await response.json();

        if (response.ok) {
            let rmaData;
            if (data.data === '') {
                rmaData = [];
            } else {
                rmaData = data.data;
            }
            sessionStorage.setItem('RMAs', JSON.stringify(rmaData));
            return rmaData;
        } else {
            // if (response.statusText === 'Unauthorized') {
            //     Auth.reAuth()
            //     .then(getRMAs());
            // }
        }
    } else {
        return JSON.parse(sessionStorage.getItem('RMAs'));
    }
}

function RMAs () {
    let navigate = useNavigate(); 
    const [rmas, setRmas] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = 'Customer Portal - RMAs';
    }, []);

    useEffect(() => {
        getRMAs()
        .then((data) => {
            setRmas(data);
            setLoading(false);
        })
    }, []);

    function navigation (props) {
        const id = invoiceNumToId()[props];
        if (id !== undefined)
            navigate('/invoices/' + id)
        else 
            toast.error('No invoice found');
    }

    return (
        <div className='orders-container'>
            {loading
            ? <Loading />
            : (<>
            <div className='list-header'>
                <h2 className='list-title'>RMAs</h2>
                {/* Put Pagination here if needed */}
            </div>
            <div className='table-wrapper'>
                <table className='table'>
                    <thead>
                        <tr className='grid-row'>
                            <th>Number</th>
                            <th>Request Date</th>
                            <th>PO #</th>
                            <th>Invoice #</th>
                            <th>Requesting Contact</th>
                            <th>Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {loading ? <tr><th><Loading /></th></tr> 
                        : 
                        rmas && rmas.length > 0 && rmas.map(rma => (
                            <tr className='' key={rma.RMA_Number}>
                                <th>{rma.RMA_Number}</th>
                                <th>{formatDate(rma.RMA_Request_Date)}</th>
                                <th>{rma.PO_Number}</th>
                                {rma.Invoice_Number.split(', ').length > 1}
                                <th>{rma.Invoice_Number.split(', ').map(inv => (
                                    <div className='link' key={inv} onClick={(e) => navigation(inv)}>{inv}</div>
                                ))}</th>
                                <th>{rma.RMA_Requesting_Contact}</th>
                                <th 
                                    style={{ color: (handleStatus(rma.RMA_Status.toLowerCase())[1] || 'black') }}
                                    >
                                        {(handleStatus(rma.RMA_Status.toLowerCase())[0] || '')}
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {(!rmas || rmas.length === 0) 
                &&
                (
                    <div className='no-data'>
                        There are no posted RMAs
                    </div>
                )}
            </>)}
        </div>
    )
}

export default RMAs;
import Auth from "./auth";

let s = 0;

export async function getTrackingAndInvoiceData (setLStatus, setMaxLoad, setDataLoaded) {
    let orders = JSON.parse(sessionStorage.getItem('orders'));
    // setLStatus(0);
    setMaxLoad(orders.length);
    let finished = 0;
    let loaded = false;
    doTheMath(0, orders, setLStatus)
    .then(() => {
        finished++;
        if (finished === 5 && !loaded) {
            loaded = true;
            gatherDealData(setLStatus, setMaxLoad)
            .then(() => {
                setDataLoaded(true);
                return true;
            })
        }
    });
    doTheMath(1, orders, setLStatus)
    .then(() => {
        finished++;
        if (finished === 5 && !loaded) {
            loaded = true;
            gatherDealData(setLStatus, setMaxLoad)
            .then(() => {
                setDataLoaded(true);
                return true;
            })
        }
    });
    doTheMath(2, orders, setLStatus)
    .then(() => {
        finished++;
        if (finished === 5 && !loaded) {
            loaded = true;
            gatherDealData(setLStatus, setMaxLoad)
            .then(() => {
                setDataLoaded(true);
                return true;
            })
        }
    });
    doTheMath(3, orders, setLStatus)
    .then(() => {
        finished++;
        if (finished === 5 && !loaded) {
            loaded = true;
            gatherDealData(setLStatus, setMaxLoad)
            .then(() => {
                setDataLoaded(true);
                return true;
            })
        }
    });
    doTheMath(4, orders, setLStatus)
    .then(() => {
        finished++;
        if (finished === 5 && !loaded) {
            loaded = true;
            gatherDealData(setLStatus, setMaxLoad)
            .then(() => {
                setDataLoaded(true);
                return true;
            })
        }
    });
}
async function doTheMath (index, orders, setLStatus) {
    if (index < orders.length - 1) {
        let order = orders[index];
        if (!order.packages) {
            const orderResponse = await fetch('/api/orders/' + order.salesorder_id, {
                method: 'get'
            });
            const {data} = await orderResponse.json();

            if (orderResponse.ok) {
                order.packages = data.salesorder.packages;
                order.invoices = data.salesorder.invoices;
                orders[index] = order;
                sessionStorage.setItem("orders", JSON.stringify(orders));
                s++;
                setLStatus(s);
                return doTheMath(index + 5, orders, setLStatus);
            }
        }
    } else {
        return;
    }
}

export async function gatherDealData (setLStatus) {
    const response = await fetch('/api/deals', {
        method: 'post',
        body: JSON.stringify({
            account_name: JSON.parse(sessionStorage.getItem("account")).company_name
        }),
        headers: {
            "Content-Type": "application/json"
        }
    });
    const data = await response.json();
    if (response.ok) {
        function compare( a, b ) {
            if ( a.so > b.so ){
              return -1;
            }
            if ( a.so < b.so ){
              return 1;
            }
            return 0;
          }
        data.data.sort( compare );
        setLStatus(100);
        sessionStorage.setItem("dd",JSON.stringify(data.data));
        sessionStorage.setItem('edl',true);
        return true;
    } else {
        sessionStorage.setItem('edl',true);
        return true;
    }
}

export async function gatherUserData () {
    const response = await fetch('/api/users', {
        method: 'post',
        body: JSON.stringify({
          account_id: Auth.getProfile().account_id[Auth.getAccountIndex()],
          contact_id: Auth.getProfile().contact_id[Auth.getAccountIndex()]
        }),
        headers : {
            "Content-Type": "application/json"
        }
    });
    const data = await response.json();

    if (response.ok) {
        sessionStorage.setItem('user', JSON.stringify(data.data.contact_person));
        return JSON.stringify(data.data.contact_person);
    } else {
        if (response.statusText === 'Unauthorized') {
            // await Auth.reAuth().then(() => this.gatherUserData());
        }
        if (data.message.code === 1002) {
            // contact person does not exist in Zoho Books, delete from customer dashboard
            const response = await fetch('/api/users/dashboard/' + Auth.getProfile().zoho_id[Auth.getAccountIndex()], {
                method: 'delete',
            });
            if (response.ok) {
                Auth.logout();
            } else {
                const data = await response.json();
                console.log('Error while deleting account:', data.message);
            }
        } else {
            console.error('Error while fetching user data');
        }
    }
}

export async function gatherAccountData () {
    const response = await fetch('/api/accounts/' + Auth.getProfile().account_id[Auth.getAccountIndex()], {
        method: 'get'
    });

    const data = await response.json();

    if (response.ok) {
        sessionStorage.setItem('account', JSON.stringify(data.data.contact));
        return data.data;
    } else {
        console.error('Error while fetching account data');
    }
}

export async function gatherOrderData (page) {
    const response = await fetch('/api/orders/all/' + Auth.getProfile().account_id[Auth.getAccountIndex()] + '/' + page, {
        method: 'get'
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        console.error('Error while fetching order data');
    }
}

export async function gatherInvoiceData (page) {
    const response = await fetch('/api/invoices/all/' + Auth.getProfile().account_id[Auth.getAccountIndex()] + '/' + page, {
        method: 'get'
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        console.error('Error while fetching invoice data');
    }
}

export async function gatherPaymentData () {
    const response = await fetch('/api/payments/' + Auth.getProfile().account_id[Auth.getAccountIndex()], {
        method: 'get'
    });

    const data = await response.json();

    if (response.ok) {
        sessionStorage.setItem('payments', JSON.stringify(data.data));
        return data.data;
    } else {
        console.error('Error while fetching payment data');
    }
}


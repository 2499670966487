import React, { useState, useEffect } from 'react';
import InvoiceList from '../../components/InvoiceList';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import Loading from '../../components/Loading';
import ReactPaginate from 'react-paginate';
import GLOBALS from '../../utils/global_variables';

function Invoices () {
    useEffect(() => {
        document.title = 'Customer Portal - Invoices';
    }, []);

    const [invoices, setInvoices] = useState();
    const [loading, setLoading] = useState(true);

    const headers = ['Invoice #', 'Reference #', 'Date', 'Total', 'Status'];

    // Filtering the invoices
    const [currentFilter, setCurrentFilter] = useState({});
    const handleFilterClick = (event) => {
        const toFilter = event.target.id ? event.target.id : currentFilter.filter;
        const direction = currentFilter.filter === toFilter 
                        ? (currentFilter.direction === 'asc' ? 'desc' : 'asc') 
                        : currentFilter.direction;
        setCurrentFilter({ filter: toFilter, direction });

        const setName = (filterName) => {
            if (filterName === 'invoice #') return {whatToFiler: 'invoice_num', sub: 4}
            else return {whatToFilter: filterName, sub: 0}
        }

        setInvoices(filterData(setName(toFilter.toLowerCase()), direction, invoices));
        setEndOffset(itemOffset + itemsPerPage);
            setCurrentItems(invoices.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(invoices.length / itemsPerPage));
    }

    // Filter data based on data sent, and 'whatToFilter' which is a key in an obj
    const filterData = ({whatToFilter, sub}, directionToFilter, data) => {
        const filteredData = data.sort((a, b) => {
            const dataNumA = typeof a[whatToFilter] === 'string' 
                            ? a[whatToFilter].substring(sub) 
                            : a[whatToFilter];
            const dataNumB = typeof b[whatToFilter] === 'string' 
                            ? b[whatToFilter].substring(sub) 
                            : b[whatToFilter];
            return (directionToFilter === 'asc' 
                    ? (dataNumA < dataNumB) 
                        ? 1 
                        : -1 
                    : -1
            );
        });
        return filteredData;
    }

    // Load invoices
    useEffect(() => {
        try {
            setInvoices(JSON.parse(sessionStorage.getItem('invoices')));
        } catch (e) {
            console.log(e);
        }
    }, []);

    // Page Pagination
    // DEFAULT FOR HOW MANY TO DISPLAY PER PAGE
    const [itemsPerPage, setItemsPerPage] = useState(GLOBALS.getPerPage());
    // rest to handle changes
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState();
    const [currentItems, setCurrentItems] = useState();
    const [pageCount, setPageCount] = useState();
    useEffect(() => {
        if (invoices) {
            setEndOffset(itemOffset + itemsPerPage);
            setCurrentItems(invoices.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(invoices.length / itemsPerPage));
            setLoading(false);
        }
    }, [itemsPerPage, itemOffset, invoices, endOffset]);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % invoices.length;
        setItemOffset(newOffset);
    };
    const handlePerPageChange = (event) => {
        GLOBALS.setPerPage(event.target.value);
        setItemsPerPage(parseInt(event.target.value))
    }

    return (
        <div className='invoices-container'>
            {loading 
            ? <Loading />
            : 
            (<>
            <div className='list-header'>
                <h2 className='list-title'>Invoices </h2>
                <div className='list-page-container'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            <div className='table-wrapper'>
                <table className='table'>
                    {/* Table header */}
                    <thead>
                        <tr>
                            {/* Map the heading categories, showing graphical input for filter */}
                            {headers.map(item => (
                                (item !== 'Reference #' 
                                ? 
                                (<th className={`filter ${currentFilter.filter === item && 'filtered'}`} 
                                    id={item} 
                                    key={item}
                                    onClick={handleFilterClick}
                                >
                                    {item} {currentFilter.filter === item ? 
                                        (currentFilter.direction === 'asc' ? <IoChevronUpOutline style={{ color: 'black' }} /> : <IoChevronDownOutline style={{ color: 'black' }} />) 
                                            : <></>}
                                </th>) 
                                :
                                (<th className='' key={item}>{item}</th>)
                            )))}
                        </tr>
                    </thead>
                    
                    {/* Table body */}
                    <tbody>
                        {invoices && invoices.length > 0 && currentItems.map(invoice => (
                            <InvoiceList key={invoice.invoice_id} invoice={invoice} />
                        ))}
                    </tbody>
                </table>
            </div>

            {(!invoices || invoices.length === 0) 
                ?
                (
                    <div className='no-data'>
                        There are no invoices for your account
                    </div>
                )
                :
                (
                    <div className='list-information'>
                        <span>Showing {itemOffset + 1}-{endOffset > invoices.length ? invoices.length : endOffset} of {invoices.length}</span>
                        <div>
                            <span>Per page: </span>
                            <select name="records-per-page" id="records-per-page" 
                                value={itemsPerPage} 
                                onChange={(e) => handlePerPageChange(e)}
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                )
            }
            </>)}
        </div>
    );
};

export default Invoices;
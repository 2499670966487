import React, { useState, useEffect } from 'react';
import SingleOrderList from '../../components/OrderList';
import Loading from '../../components/Loading';
import ReactPaginate from 'react-paginate';
import GLOBALS from '../../utils/global_variables';

function Orders () {
    useEffect(() => {
        document.title = 'Customer Portal - Orders';
    }, []);

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        try {
            setOrders(JSON.parse(sessionStorage.getItem('orders')).filter(order => order.order_status !== "draft" && order.order_status !== "pending_approval"));
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }, []);

    // Page Pagination
    // DEFAULT FOR HOW MANY TO DISPLAY PER PAGE
    const [itemsPerPage, setItemsPerPage] = useState(GLOBALS.getPerPage());
    // rest to handle changes
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState();
    const [currentItems, setCurrentItems] = useState();
    const [pageCount, setPageCount] = useState();
    useEffect(() => {
        setEndOffset(itemOffset + itemsPerPage);
        setCurrentItems(orders.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(orders.length / itemsPerPage));
    }, [itemsPerPage, itemOffset, orders, endOffset]);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % orders.length;
        setItemOffset(newOffset);
    };
    const handlePerPageChange = (event) => {
        GLOBALS.setPerPage(event.target.value);
        setItemsPerPage(parseInt(event.target.value))
    }

    return (
        <div className='orders-container'>
            {loading 
            ? <Loading />
            : 
            (<>
            <div className='list-header'>
                <h2 className='list-title'>Sales Orders </h2>
                <div className='list-page-container'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            <div className='table-wrapper'>
            <table className='table'>
                <thead>
                    <tr className='grid-row'>
                        <th className=''>Sales Order #</th>
                        <th className=''>Purchase Order #</th>
                        <th className=''>Reference #</th>
                        <th className=''>Date</th>
                        <th className=''>Total</th>
                    </tr>
                </thead>

                <tbody>
                    {(orders && orders.length > 0) 
                        && 
                        currentItems.map(order => (
                            <SingleOrderList key={order.salesorder_id} order={order} />
                        ))}
                </tbody>
            </table>
            </div>

            {(!orders || orders.length === 0) 
                ?
                (
                    <div className='no-data'>
                        There are no orders for your account
                    </div>
                )
                :
                (
                    <div className='list-information'>
                        <span>Showing {itemOffset + 1}-{endOffset > orders.length ? orders.length : endOffset} of {orders.length}</span>
                        <div>
                            <span>Per page: </span>
                            <select name="records-per-page" id="records-per-page" 
                                value={itemsPerPage} 
                                onChange={(e) => handlePerPageChange(e)}
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                )
            }
            </>)}
        </div>
    );
}

export default Orders;
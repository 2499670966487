let PER_PAGE;
class GLOBAL_VARS {
    getPerPage () {
        return PER_PAGE;
    }

    setPerPage (amount) {
        if (amount !== null && amount !== undefined) {
            if(typeof amount === 'string' || amount instanceof String)
                PER_PAGE = parseInt(amount);
            else
                PER_PAGE = amount;
            localStorage.setItem("per-page",JSON.stringify(PER_PAGE));
        }
    }

    getDefaultsFromStorage () {
        // PER PAGE
        const ppLocal = JSON.parse(localStorage.getItem("per-page"));
        if (ppLocal === null || (ppLocal !== 10 && ppLocal !== 25 && ppLocal !== 50 && ppLocal !== 100))
            PER_PAGE = 50;
        else
            PER_PAGE = ppLocal;
    }

}
export default new GLOBAL_VARS();